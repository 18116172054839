import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import { BsBoxArrowInRight } from 'react-icons/bs';
import { Button, withStyles, Grid, Divider, Box } from '@material-ui/core';

import Layout, { LayoutTitle } from 'components/Layout';
import SeoHelmet from 'components/SeoHelmet';
import PhotosQuery from 'components/Queries/PhotosQuery';
import VideosQuery from 'components/Queries/VideosQuery';

const styles = ({ spacing }) => ({
  subtitle: {
    marginTop: spacing(3),
    marginBottom: spacing(1),
  },
  moreBtn: {
    textTransform: 'none',
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
});

function Portofoliu(props) {
  const { classes } = props;
  return (
    <Layout>
      <SeoHelmet title="Proiecte / Lucrari" />
      <LayoutTitle title="Proiecte / Lucrari" />
      <Grid justify="center" container spacing={2}>
        <Grid item xs={12}>
          <PhotosQuery limit={2} filter="portofoliu" />
          <Box className={classes.buttonWrap}>
            <Button
              onClick={() => {}}
              component={Link}
              color="secondary"
              to="/portofoliu/poze"
              className={classes.moreBtn}
              autoCapitalize={false}>
              <BsBoxArrowInRight size="1rem" /> &nbsp;Vezi mai mult
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <VideosQuery />
          <Box className={classes.buttonWrap}>
            <Button
              disabled
              onClick={() => {}}
              // component={Link}
              color="secondary"
              // to="/portofoliu/poze"
              className={classes.moreBtn}
              autoCapitalize={false}>
              <BsBoxArrowInRight size="1rem" /> &nbsp;Vezi mai mult
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

Portofoliu.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Portofoliu);
