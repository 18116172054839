/**
 * Created by Alexandru Huszar on 2/25/2021.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { withStyles, Grid } from '@material-ui/core';

const styles = ({ spacing }) => ({
  videosWrap: {
    marginTop: spacing(2),
  },
});
/**
 * Videos
 */
function Videos(props) {
  const { videos, classes } = props;
  const videosUrl = videos.map(item => ({
    id: item.node.id,
    url: item.node.permalink_url,
  }));

  /**
   * Render the component
   *
   * @return {JSX.Element}
   */
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes.videosWrap}>
      {videosUrl.map(video => (
        <Grid item xs={12} md={4}>
          <ReactPlayer
            url={`https://www.facebook.com${video.url}?target="_top"`}
            width="auto"
            height="auto"
            key={video.id}
          />
        </Grid>
      ))}
    </Grid>
  );
}

Videos.propTypes = {
  classes: PropTypes.object,
  videos: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(Videos);
