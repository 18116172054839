import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Videos from 'components/Videos';

const getVideosPortfolio = graphql`
  {
    allFacebookVideos {
      edges {
        node {
          id
          permalink_url
        }
      }
    }
  }
`;

const VideosQuery = () => {
  const data = useStaticQuery(getVideosPortfolio);
  return <Videos videos={data.allFacebookVideos.edges} />;
};

export default VideosQuery;
